import * as React from "react";
import { locKeys as defaultLocKeys } from "../../utility/l10n";
import { timesCannonical as defaultTimesCannonical } from "../../utility/timeCannonical";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkSelect } from "@egds/react-core/input";
import { TimeDropdownProps } from "./typings";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

export const TimeDropdown: React.FunctionComponent<TimeDropdownProps> = (props) => {
  const {
    labelKey,
    timeValues,
    locKeys,
    timeCannonical,
    invalidKey,
    colSpan,
    onChange,
    value,
    use12hr,
    showTimeIcon = true,
    dataTestId,
  } = props;
  const keyHelper = new ItemKeyHelper("TimeDropdown");
  const { formatText } = useLocalization();
  const label = formatText(labelKey);
  const use12HourTime = use12hr || false;

  if (timeValues?.length === 0) return null;
  const timeVals = locKeys || defaultLocKeys;
  const timeCanonicalArray = timeCannonical || defaultTimesCannonical;

  const optionListItems = timeCanonicalArray.map((time, index) => (
    <option data-time={time} value={timeValues[index]} key={keyHelper.next()}>
      {use12HourTime ? formatText(timeVals.time12hrs[index]) : formatText(timeVals.time24hrs[index])}
    </option>
  ));

  return (
    <UitkLayoutGridItem colSpan={colSpan}>
      <div>
        <UitkSelect
          aria-label={label}
          icon={showTimeIcon ? "access_time" : undefined}
          invalid={formatText(invalidKey!)}
          label={label}
          onChange={onChange}
          value={value}
          data-testid={dataTestId}
        >
          {optionListItems}
        </UitkSelect>
      </div>
    </UitkLayoutGridItem>
  );
};
