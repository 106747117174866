import * as React from "react";
import { observer } from "mobx-react";
import { CarsFilterHiddenSearchData } from "components/flexComponents/WizardCarPWA/components/HiddenSearchData/CarsFilterHiddenSearchData";
import { CarsHiddenSearchDataProps } from "components/flexComponents/WizardCarPWA/typings";

export const CarsHiddenSearchData = observer((props: CarsHiddenSearchDataProps) => {
  const isDifferentDropoff = props.lobState.isDifferentDropOff;
  const pageName = props.pageName;
  const [differentDropoff, setDifferentDropoff] = React.useState(false);

  React.useEffect(() => {
    setDifferentDropoff(isDifferentDropoff || false);
  }, [isDifferentDropoff]);

  return (
    <div>
      {/*Origin location*/}
      <input
        type="hidden"
        name="pickupIATACode"
        value={props.lobState.location.origin.metaData.ttla}
        data-testid="originCode-input"
      />
      <input
        type="hidden"
        name="dpln"
        value={props.lobState.location.origin.metaData.regionId}
        data-testid="originId-input"
      />
      {/*Destination location*/}
      <input
        type="hidden"
        name="returnIATACode"
        value={props.lobState.location.destination.metaData.ttla}
        data-testid="destinationCode-input"
      />
      <input
        type="hidden"
        name="drid1"
        value={differentDropoff ? props.lobState.location.destination.metaData.regionId : ""}
        data-testid="destinationId-input"
      />
      <input type="hidden" name="time1" value={props.lobState.pickupTime} data-testid="pickupTime" />
      <input type="hidden" name="time2" value={props.lobState.returnTime} data-testid="returnTime" />
      {/*  Constant value submission parameters required for Cars path */}
      <input type="hidden" name="olat" value={props.lobState.olat} data-testid="olat" />
      <input type="hidden" name="olon" value={props.lobState.olon} data-testid="olon" />
      <input type="hidden" name="dlat" value={props.lobState.dlat} data-testid="dlat" />
      <input type="hidden" name="dlon" value={props.lobState.dlon} data-testid="dlon" />
      <input type="hidden" name="dagv" value="1" data-testid="dagv" />
      <input type="hidden" name="subm" value="1" data-testid="subm" />
      <input type="hidden" name="fdrp" value="0" data-testid="fdrp" />
      <input type="hidden" name="ttyp" value="2" data-testid="ttyp" />
      <input type="hidden" name="acop" value="2" data-testid="acop" />
      <input type="hidden" name="rdus" value="10" data-testid="rdus" />
      <input type="hidden" name="rdct" value="1" data-testid="rdct" />
      <input type="hidden" name="styp" value="4" data-testid="styp" />
      <input type="hidden" name="rfrr" value={pageName} data-testid="rfrr" />

      <CarsFilterHiddenSearchData templateComponent={props.templateComponent} />
    </div>
  );
});
