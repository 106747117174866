import * as React from "react";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { locKeys } from "./l10n";
import { useLocalization } from "@shared-ui/localization-context";

export interface AARPCheckboxProps {
  isAARPChecked: boolean;
  onAARPChange: () => void;
}

export const AARPCheckbox: React.FunctionComponent<AARPCheckboxProps> = ({ onAARPChange, isAARPChecked }) => {
  const { formatText } = useLocalization();
  const checkboxValue = isAARPChecked ? "on" : "off";

  return (
    <UitkLayoutGridItem colSpan="all">
      <div>
        <UitkCheckbox
          id="aarp-checkbox-toggle"
          description={formatText(locKeys.aarpDescription)}
          checked={isAARPChecked}
          onChange={onAARPChange}
        >
          {formatText(locKeys.aarpLabel)}
        </UitkCheckbox>
        <input type="hidden" name="aarpcr" value={checkboxValue} data-testid="aarp-input" />
      </div>
    </UitkLayoutGridItem>
  );
};
