import * as React from "react";
import { observer } from "mobx-react";

import { CarsFormHotwireView } from "../components/CarsFormView/CarsFormHotwireView";
import { WizardCarPWAViewProps } from "../typings";
import {
  useHotwireCarGeolocationOverrides,
  useHotwireCarOverrides,
} from "components/flexComponents/WizardCarPWA/utils/hotwireCarOverrides";
import { getFmId } from "src/stores/ExperienceTemplateStore";

export const WizardCarPWAHotwireView: React.FunctionComponent<WizardCarPWAViewProps> = observer(
  ({
    isDiffDropOff,
    onDifferentDropOffSelected,
    setIsDifferentDropOff,
    wizardState: { globalWizardState, carWizardState },
    setInputsRefs,
    getLocError,
    templateComponent,
    wizardCarPWAModel,
  }) => {
    const { config } = carWizardState;
    const {
      config: { fmTitleId },
    } = templateComponent;

    const fmId = getFmId(templateComponent);

    useHotwireCarOverrides(carWizardState);
    useHotwireCarGeolocationOverrides(carWizardState, wizardCarPWAModel.defaultToUserLocation);

    return (
      <form
        noValidate
        action={config.form.action}
        autoComplete="off"
        className="WizardCarPWA"
        id={config.elementId}
        data-fm={fmId}
        data-fm-title-id={fmTitleId}
        data-module="searchCarForm"
        onSubmit={carWizardState.submit}
      >
        <CarsFormHotwireView
          templateComponent={templateComponent}
          setInputsRefs={setInputsRefs}
          carWizardState={carWizardState}
          carConfig={config}
          onDifferentDropOffSelected={onDifferentDropOffSelected}
          setIsDifferentDropOff={setIsDifferentDropOff}
          isDiffDropOff={isDiffDropOff}
          getLocError={getLocError}
          globalConfig={globalWizardState.config}
        />
      </form>
    );
  }
);

export default WizardCarPWAHotwireView;
