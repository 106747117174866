import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import {
  CarsFilterHiddenSearchDataProps,
  WizardCarPWAFlexModelResult,
} from "components/flexComponents/WizardCarPWA/typings";
import { isAddCSRFilter } from "components/flexComponents/WizardCarPWA/utils/isAddCSRFilter";

export const CarsFilterHiddenSearchData = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: CarsFilterHiddenSearchDataProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;

    if (flexModuleModelStore && context) {
      const { searchContext } = context;
      const {
        metadata: { id },
      } = templateComponent;

      const model = flexModuleModelStore.getModel(id) as WizardCarPWAFlexModelResult | null;

      if (!model) {
        return null;
      }

      if (isAddCSRFilter(model)) {
        switch (searchContext.pageType) {
          case "Travel-Guide-CarRentalCompany":
          case "Car-Rental-Guide-Suppliers":
          case "Car-Rental-Destination-Suppliers":
            const supplierCode =
              context.site.brand === "hotwire"
                ? searchContext.carSupplier.code
                : `["${searchContext.carSupplier.code}"]`;
            return <input type="hidden" name="selVen" value={supplierCode} data-testid="selVen" />;
          case "Car-Rental-Guide-Car-Class":
            return (
              <input
                type="hidden"
                name="selCC"
                value={`["${searchContext.carClass.toLowerCase()}"]`}
                data-testid="selCC"
              />
            );
          default:
            return null;
        }
      }
    }

    return null;
  })
);
