import * as React from "react";
import { observer } from "mobx-react";
import { CarsHiddenSearchDataProps } from "components/flexComponents/WizardCarPWA/typings";
import { useLocalization } from "@shared-ui/localization-context";

export const CarTrawlerHiddenSearchData = observer((props: CarsHiddenSearchDataProps) => {
  const { formatDateString } = useLocalization();
  return (
    <div>
      <input
        type="hidden"
        name="pickupDateTime"
        value={`${formatDateString(props.lobState.date.start, { raw: "yyyy-MM-dd" })}T${props.lobState.pickupTime}`}
        data-testid="pickupTime"
      />
      <input
        type="hidden"
        name="returnDateTime"
        value={`${formatDateString(props.lobState.date.end, { raw: "yyyy-MM-dd" })}T${props.lobState.returnTime}`}
        data-testid="returnTime"
      />
      <input
        type="hidden"
        name="pickupIATACode"
        value={props.lobState.location.origin.metaData.ttla}
        data-testid="originCode-input"
      />
      <input
        type="hidden"
        name="returnIATACode"
        value={props.lobState.location.destination.metaData.ttla}
        data-testid="destinationCode-input"
      />
      <input type="hidden" name="clientID" value={props.lobState.config.clientID} data-testid="clientID" />
      <input type="hidden" name="residenceID" value={props.lobState.config.residenceID} data-testid="residenceID" />
      <input type="hidden" name="age" value={props.lobState.config.age} data-testid="age" />
    </div>
  );
});
