import * as React from "react";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";

import { CarsHotwireHiddenSearchDataProps } from "components/flexComponents/WizardCarPWA/typings";
import { withStores } from "stores";
import { CarsFilterHiddenSearchData } from "./CarsFilterHiddenSearchData";

const formatSearchDateTime = (date: Date, time: string, formatDate: (date: Date, options: any) => string) =>
  `${formatDate(date, { raw: "yyyy-MM-dd" })}T${time}:00`;

export const CarsHotwireHiddenSearchData = withStores("wizardState")(
  observer(({ wizardState, templateComponent }: CarsHotwireHiddenSearchDataProps) => {
    const { formatDate } = useLocalization();

    const { start, end } = wizardState.carWizardState.date;
    const { pickupTime, returnTime } = wizardState.carWizardState;

    const formattedStartDate = formatSearchDateTime(start, pickupTime, formatDate);
    const formattedEndDate = formatSearchDateTime(end, returnTime, formatDate);

    return (
      <>
        <input type="hidden" name="start" value={formattedStartDate} data-testid="pickupTime" />
        <input type="hidden" name="end" value={formattedEndDate} data-testid="returnTime" />
        <CarsFilterHiddenSearchData templateComponent={templateComponent} />
      </>
    );
  })
);
