import * as React from "react";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { DriverAgenInRangeToggleProps } from "../typings";
import { locKeys } from "./l10n";
import { useLocalization } from "@shared-ui/localization-context";

export const DriverAgeInRangeToggle: React.FunctionComponent<DriverAgenInRangeToggleProps> = ({
  onDriverAgeInRange,
  isDriverAgeInRange,
  carConfig,
}) => {
  const { formatText } = useLocalization();
  const { driverAgeRange, driverAgeDescription } = locKeys;
  const { minRangeAge, maxRangeAge } = carConfig;

  return (
    <UitkLayoutGridItem colSpan="all">
      <div>
        <UitkCheckbox
          id="driver-age-in-range-toggle"
          description={formatText(driverAgeDescription, minRangeAge, maxRangeAge)}
          checked={!isDriverAgeInRange}
          onChange={onDriverAgeInRange}
        >
          {formatText(driverAgeRange, minRangeAge, maxRangeAge)}
        </UitkCheckbox>
        <input
          type="hidden"
          name="ageInRange"
          value={isDriverAgeInRange.toString()}
          data-testid="driver-age-in-range-input"
        />
      </div>
    </UitkLayoutGridItem>
  );
};
