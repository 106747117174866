import * as React from "react";
import { timeValues, timeValues24HoursFormat } from "src/components/shared/TimeDropdown/config/time/timeValues";

export interface GTHiddenSearchDataProps {
  pickUpTime: string;
  dropOffTime?: string;
  direction?: boolean;
  airportCode: string;
  hotelId: string;
  regionId: string;
}

export const GTHiddenSearchData = (props: GTHiddenSearchDataProps) => {
  const pickUpTime = timeValues24HoursFormat[timeValues.indexOf(props.pickUpTime)];
  const dropOffTime = props.dropOffTime
    ? timeValues24HoursFormat[timeValues.indexOf(props.dropOffTime)]
    : props.dropOffTime;
  const fromAirport = props.direction;

  return (
    <>
      <input type="hidden" name="pickUpTime" value={pickUpTime} data-testid="pickupTime" />
      <input type="hidden" name="dropOffTime" value={dropOffTime} data-testid="dropOffTime" />
      <input type="hidden" name="airportCode" value={props.airportCode} data-testid="airportCode" />
      <input type="hidden" name="hotelId" value={props.hotelId} data-testid="hotelId" />
      <input type="hidden" name="regionId" value={props.regionId} data-testid="regionId" />
      {!fromAirport && <input type="hidden" name="direction" value="H2A" data-testid="locationDirection" />}
    </>
  );
};
