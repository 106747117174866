import * as React from "react";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { DifferentDropOffToggleProps } from "../../typings";
import { useLocalization } from "@shared-ui/localization-context";

export const DifferentDropOffToggle: React.FunctionComponent<DifferentDropOffToggleProps> = ({
  onDifferentDropOffSelected,
  isDiffDropOff,
  labelToken,
}) => {
  const { formatText } = useLocalization();

  return (
    <UitkLayoutGridItem colSpan="all">
      <div>
        <UitkCheckbox
          id="diff-drop-of-toggle"
          checked={isDiffDropOff}
          onChange={onDifferentDropOffSelected}
          data-testid="diff-drop-of-toggle"
        >
          {formatText(labelToken)}
        </UitkCheckbox>
      </div>
    </UitkLayoutGridItem>
  );
};
