import * as React from "react";

import { UitkTertiaryButton } from "@egds/react-core/button";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkIcon } from "@egds/react-core/icons";

import { useLocalization } from "@shared-ui/localization-context";

import { DifferentDropOffToggleProps } from "../../typings";

export const DifferentDropOffToggleButton: React.FunctionComponent<DifferentDropOffToggleProps> = ({
  onDifferentDropOffSelected,
  isDiffDropOff,
  labelToken,
}) => {
  const { formatText } = useLocalization();

  return (
    <UitkLayoutPosition position={{ left: "minus-four", top: "one" }} type="relative">
      <UitkTertiaryButton
        onClick={onDifferentDropOffSelected}
        type="button"
        data-testid="different-dropoff-location-link-toggle"
      >
        <UitkIcon name={isDiffDropOff ? "remove" : "add"} />
        {formatText(labelToken)}
      </UitkTertiaryButton>
    </UitkLayoutPosition>
  );
};
