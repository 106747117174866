import { observer } from "mobx-react";
import * as React from "react";

import { UitkSpacing } from "uitk-react-spacing";
import { UitkSwitch } from "uitk-react-switch";
import { CarWizardStateProps } from "components/flexComponents/WizardCarPWA/typings";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useLocalization } from "@shared-ui/localization-context";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

export const CarAddPWPToggle = observer(({ wizardState }: CarWizardStateProps) => {
  const { formatText } = useLocalization();

  const track = useClickTracker();

  const getTrackingConfig = (isPayWithPointsChecked: boolean): FlexTrackingInfo => {
    const key = isPayWithPointsChecked;

    return {
      moduleName: "wizard-car-pwa",
      action: Action.CLICK,
      linkName: `${key} wizard v2 expando`,
      rfrr: `pwpToggle.${key}`,
    };
  };

  const handleChange = () => {
    wizardState.togglePayWithPointsChecked();
    const trackInfo = getTrackingConfig(wizardState.isPayWithPointsChecked);
    sendDelayedClickstreamTrackEvent(trackInfo, track);
  };

  const changeLabelOnToggle = () => {
    if (wizardState.isPayWithPointsChecked) return formatText(wizardState.config.payWithPointsCheckedToken as string);
    return formatText(wizardState.config.payWithCashCheckedToken as string);
  };
  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <div data-testid="pwp-toggle">
        <UitkSwitch
          checked={wizardState.isPayWithPointsChecked}
          onChange={handleChange}
          switchPosition="trailing"
          value={String(wizardState.isPayWithPointsChecked)}
        >
          {changeLabelOnToggle()}
        </UitkSwitch>
        <input
          type="hidden"
          name="useRewards"
          value={String(wizardState.isPayWithPointsChecked)}
          data-testid="cars-pwp-toggle-input"
        />
      </div>
    </UitkSpacing>
  );
});
