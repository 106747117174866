import * as React from "react";
import { inject, observer } from "mobx-react";
import { CarSubLOBs } from "src/stores/wizard/state/typings";
import { WizardCarPWAProps, WizardCarPWAState, WizardCarPWAFlexModelResult } from "./typings";
import { WizardCarPWAView } from "./views/WizardCarPWAView";
import { WizardCarPWAHotwireView } from "./views/WizardCarPWAHotwireView";

import { isVariantEnabled } from "../../utility/experiment";
import { getOffsetDatesByDatesOption } from "src/components/utility/OffsetDate/OffsetDateUtil";

import { WizardCarPWAViews } from "./utils/WizardCarPWAViews";
import { isAddCSRFilter } from "components/flexComponents/WizardCarPWA/utils/isAddCSRFilter";
import { toBooleanOrUndefined } from "components/utility/toBooleanOrUndefined";
import { getQueryParamValue } from "components/utility/UrlUtils";

const getComponentFromView = (view?: string) => {
  switch (view) {
    case WizardCarPWAViews.HOTWIRE:
      return WizardCarPWAHotwireView;
    default:
      return WizardCarPWAView;
  }
};

@inject("wizardState", "compositionStore", "flexModuleModelStore", "page")
@observer
export class WizardCarPWA extends React.Component<WizardCarPWAProps, WizardCarPWAState> {
  private model: WizardCarPWAFlexModelResult;

  constructor(props: WizardCarPWAProps) {
    super(props);
    this.state = {
      isDiffDropOff: false,
      isDriverAgeInRange: true,
      isAARPChecked: false,
    };

    const { wizardState, templateComponent, flexModuleModelStore, context } = this.props;
    const { carWizardState } = wizardState;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) return;

    const {
      metadata: { id },
    } = templateComponent;
    const model = flexModuleModelStore.getModel(id) as WizardCarPWAFlexModelResult | null;

    if (!model) return;

    this.model = model;

    carWizardState.overrideConfig(() => {
      carWizardState.config.elementId = id;
      carWizardState.config.heading.fixedTitle = this.model.fixedLobTitle;
      carWizardState.config.heading.lobSubtitleBreakPoint = this.model.lobSubtitleBreakPoint;
      carWizardState.config.heading.hideLobTitle = this.model.hideLobTitle;
      carWizardState.config.includeGT = this.model.includeGT ?? carWizardState.config.includeGT;
      carWizardState.config.disableVendorCouponCode =
        this.model.disableVendorCouponCode ?? carWizardState.config.disableVendorCouponCode;
      carWizardState.config.iHaveDiscount = carWizardState.config.disableVendorCouponCode
        ? "wizard.car.having.brand.title"
        : "wizard.car.having.discount";

      if (
        context.searchContext.pageType === "Car-Rental-Guide" &&
        isVariantEnabled(context, "Cars_Wizard_Editable_CTA_texts")
      ) {
        carWizardState.config.form.submitButton.locToken = "wizard.search.btn.copy.find-deals";
      }

      if (this.model.titleFromPageHeader) {
        carWizardState.config.heading.differentiatedTitles = false;
      }
      const addGoogleFallback = (essFeatures: string): string => {
        if (essFeatures.indexOf("google") < 0) {
          return `${essFeatures}|google`;
        }

        return essFeatures;
      };
      const essFeaturesDest = carWizardState.config.location.destination.essAdapterConfig.features;
      const essFeaturesOrigin = carWizardState.config.location.origin.essAdapterConfig.features;
      carWizardState.config.location.destination.essAdapterConfig.features = addGoogleFallback(essFeaturesDest);
      carWizardState.config.location.origin.essAdapterConfig.features = addGoogleFallback(essFeaturesOrigin);

      if (isVariantEnabled(context, "Cars_Lift30DaysRestrictionOnEMEA")) {
        carWizardState.config.date.maxDaysRange = 329;
      }

      if (context.searchContext.pageType === "Car-Rental-Supplier-Locations" && isAddCSRFilter(this.model)) {
        const { carSupplierLocation, carSupplier } = context.searchContext;
        carWizardState.location.origin.value = `${carSupplier.name} - ${carSupplierLocation.address.firstAddressLine}, ${carSupplierLocation.address.city}, ${carSupplierLocation.address.province} ${carSupplierLocation.address.postalCode}`;
      }

      const { defaultOffsetDates } = carWizardState.config.date;
      const { startDateOffset, endDateOffset } = getOffsetDatesByDatesOption(this.model);

      if (defaultOffsetDates && startDateOffset !== undefined && endDateOffset !== undefined) {
        defaultOffsetDates.start = startDateOffset;
        defaultOffsetDates.end = endDateOffset;
      }
      const useRewards = toBooleanOrUndefined(getQueryParamValue("useRewards"));
      carWizardState.config.enableCarsPWPToggle = this.model.showCarsPWPToggle || useRewards !== undefined;
      if (useRewards !== undefined) {
        carWizardState.isPayWithPointsChecked = useRewards;
      }
    });
    carWizardState.updateDateFromConfig();
  }

  public componentDidMount() {
    const { wizardState, templateComponent } = this.props;
    const { carWizardState } = wizardState;

    if (this.isGTFlexModule(templateComponent.type)) {
      carWizardState.updateSubLOBState(CarSubLOBs.GROUND_TRANSPORTATION);
    } else {
      this.setState(() => ({
        isDiffDropOff: wizardState.carWizardState.isDifferentDropOff,
      }));
      carWizardState.updateSubLOBState(CarSubLOBs.RENTAL);
    }
  }

  /* Making sure the subLob does not reset to car for wizard-gt-pwa module (gtOnly module) when the component updates */
  public componentDidUpdate() {
    const { wizardState, templateComponent } = this.props;

    if (this.isGTFlexModule(templateComponent.type)) {
      wizardState.carWizardState.updateSubLOBState(CarSubLOBs.GROUND_TRANSPORTATION);
    }
  }

  private isGTFlexModule = (flexModuleName?: string) => flexModuleName === "wizard-gt-pwa";

  private setSelectedTabOptions = (selectedTab: CarSubLOBs) => {
    const { wizardState } = this.props;

    wizardState.carWizardState.updateSubLOBState(selectedTab);
  };

  private toggleDifferentDropOff = () => {
    const { wizardState } = this.props;

    this.setState(({ isDiffDropOff }) => ({
      isDiffDropOff: !isDiffDropOff,
    }));
    wizardState.carWizardState.toggleIsDifferentDropOff();
  };

  private setIsDifferentDropOff = (differentDropOff: boolean) => {
    const { wizardState } = this.props;

    this.setState(() => ({
      isDiffDropOff: differentDropOff,
    }));

    wizardState.carWizardState.setIsDifferentDropoff(differentDropOff);
  };

  private toggleDriverAgeInRange = () => {
    this.setState(({ isDriverAgeInRange }) => ({
      isDriverAgeInRange: !isDriverAgeInRange,
    }));
  };

  private onAARPChange = () => {
    this.setState(({ isAARPChecked }) => ({
      isAARPChecked: !isAARPChecked,
    }));
  };

  private toggleBookRoundtrip = () => {
    const { wizardState } = this.props;

    wizardState.carWizardState.toggleBookRoundtrip();
  };

  public onTabOptionSelected = (selectedTab: CarSubLOBs) => {
    this.setSelectedTabOptions(selectedTab);
  };

  public onDifferentDropOffSelected = () => this.toggleDifferentDropOff();

  public onDriverAgeInRange = () => this.toggleDriverAgeInRange();

  private setInputsRefs = (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => {
    const { wizardInputsArray } = this.props.wizardState.carWizardState;
    wizardInputsArray.push(ref);
    if (secondRef) {
      wizardInputsArray.push(secondRef);
    }
  };

  private getLocError = (isLink: boolean) => {
    const {
      carWizardState,
      carWizardState: { config: carConfig },
    } = this.props.wizardState;

    if (!isLink) {
      return carWizardState.numberOfErrors
        ? carConfig.form.submitButton.locWarningsWithNumberToken!
        : carConfig.form.submitButton.locWarningsToken!;
    }

    return carWizardState.moreThanOneError()
      ? carConfig.form.submitButton.locFirstWarningLinkToken
      : carConfig.form.submitButton.locWarningLinkToken;
  };

  public render() {
    const { isDiffDropOff, isDriverAgeInRange, isAARPChecked } = this.state;
    const { wizardState, compositionStore, templateComponent } = this.props;

    if (!this.model) {
      return null;
    }

    const { showPageHeading, titleFromPageHeader, enableStickiness = false } = this.model;

    const ViewComponent = getComponentFromView(this.model.view);

    return (
      <ViewComponent
        {...this.props}
        onTabOptionSelected={this.onTabOptionSelected}
        toggleBookRoundtrip={this.toggleBookRoundtrip}
        onDifferentDropOffSelected={this.onDifferentDropOffSelected}
        setIsDifferentDropOff={this.setIsDifferentDropOff}
        onDriverAgeInRange={this.onDriverAgeInRange}
        onAARPChange={this.onAARPChange}
        isDiffDropOff={isDiffDropOff}
        isDriverAgeInRange={isDriverAgeInRange}
        isAARPChecked={isAARPChecked}
        showGTOnly={this.isGTFlexModule(templateComponent.type)}
        pageHeading={
          ((showPageHeading || titleFromPageHeader) && compositionStore?.composition?.pageHeading) || undefined
        }
        selectedTab={wizardState.carWizardState.subLOBState}
        getLocError={this.getLocError}
        setInputsRefs={this.setInputsRefs}
        wizardCarPWAModel={this.model}
        sticky={enableStickiness}
      />
    );
  }
}

export default WizardCarPWA;
